body {
    margin: 0;
    font-family: 'Qahiri', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: '#ff0000';
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

#login-page {
    background-image: url('assets/antenna.jpg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto;
    background-attachment: cover;
    /* min-height: 1050px; */
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
/* box-shadow: inset 0 0 5px grey; */
background: rgba(254, 254, 254, 0.5);
border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #959595;
border-radius: 8px;
}